export const useLedgerApi = async (url: string, query: Object) => {
  const config = useRuntimeConfig()
  const nuxtApp = useNuxtApp()
  let baseURL = config.public.CAR_LEDGER_API_BASE_URL
  if (process.client) {
    baseURL = ''
  }
  const result = await useFetch(url, {
    key: `${url}?${(new URLSearchParams(query)).toString()}`,
    baseURL,
    query,
    headers: {
      Accept: 'application/json;charset=utf-8',
    },
    getCachedData: (key) => {
      return nuxtApp.payload.data[key]
    },
  })

  return result
}
